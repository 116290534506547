import * as React from "react";
const SvgSiding = (props) => /* @__PURE__ */ React.createElement("svg", { width: "1rem", height: "1rem", stroke: "currentColor", viewBox: "0 0 135.37419 44.979172", id: "svg1", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview1", pagecolor: "#505050", bordercolor: "#eeeeee", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": "true", "inkscape:deskcolor": "#505050", "inkscape:document-units": "px" }), /* @__PURE__ */ React.createElement("defs", { id: "defs1" }), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1", transform: "translate(-0.04624,-45.205013)" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 10,
  strokeDasharray: "none",
  strokeOpacity: 1
}, d: "M 67.779575,87.009184 H 135.42043", id: "path1-7", "sodipodi:nodetypes": "cc" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 10,
  strokeDasharray: "none",
  strokeOpacity: 1
}, d: "M 0.04624,87.009184 H 36.013435 L 67.779575,48.380016 H 135.42043", id: "path2", "sodipodi:nodetypes": "cccc" })));
export default SvgSiding;
